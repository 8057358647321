<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="类型:">
              <el-select
                v-model="type_id"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
                @change="typeSelectOnclick"
              >
                <el-option key="type-all" label="所有" value></el-option>
                <el-option
                  v-for="item in type_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类:">
              <el-select v-model="category_id" clearable placeholder="请选择" loading-text="加载中...">
                <el-option key="type-all" label="所有" value></el-option>
                <el-option
                  v-for="item in category_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getContentList"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getContentList"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="newContentOnClick"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="content_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          @sort-change="sortMethodOnclick"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" sortable="custom" prop="ID">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="类型" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                type_map.get(scope.row.type_id)
                ? type_map.get(scope.row.type_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="分类" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.category_name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="标题">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <el-table-column label="别名">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.alias }}</p>
            </template>
          </el-table-column>
          <el-table-column label="PC端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.pc_cover_image + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.pc_cover_image]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="WAP端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.wap_cover_image + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.wap_cover_image]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="100" sortable="custom" prop="number">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="发布时间" width="105" sortable="custom" prop="updated_at">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.published_on) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="editModelOnclick(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes,prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "ContentListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      page_sort_fields: "-published_on",
      type_id: "",
      type_list: [],
      type_map: {},
      category_id: "",
      category_list: [],
      category_list_map: {},
      category_map: {},
      content_list: []
    };
  },
  created() {
    /**
     * 文章管理添加前需要同时添加文章分类和文章类型
     * 文章分类添加前需要先添加文章类型
     * 文章分类删除、新增、编辑变动后删除本地文章分类，重新获取文章分类
     * 文章类型删除、新增、编辑变动后删除本地文章类型，文章分类，重新获取文章类型和文章分类
     */
    let _me = this;
    _me.type_map = new Map();
    _me.category_map = new Map();
    _me.category_list_map = new Map();
    _me.getTypeTree();
    _me.getContentList();
  },
  methods: {
    getTypeTree() {
      let _me = this;
      let key = "contentStructureList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.type_list = val;
        for (let i of _me.type_list) {
          _me.type_map.set(i.id, i);
          _me.category_list_map.set(i.id, i.category_list);
          if (i.category_list != undefined && i.category_list.length > 0) {
            for (let j of i.category_list) {
              _me.category_map.set(j.id, j);
            }
          }
        }
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/type/show/structure", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.type_list = res.data === undefined ? [] : res.data;
            for (let i of _me.type_list) {
              _me.type_map.set(i.id, i);
              _me.category_list_map.set(i.id, i.category_list);
              if (i.category_list != undefined && i.category_list.length > 0) {
                for (let j of i.category_list) {
                  _me.category_map.set(j.id, j);
                }
              }
            }
            _me.$common.setGlobalStorage(key, _me.type_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    typeSelectOnclick(value) {
      let _me = this;
      _me.category_id = "";
      _me.category_list = _me.category_list_map.get(value)
        ? _me.category_list_map.get(value)
        : [];
    },
    getContentList(page) {
      let _me = this;
      if (page) {
        _me.page_number = page;
      }

      let params = {
        type_id: _me.type_id,
        category_id: _me.category_id,
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: _me.page_sort_fields
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/article/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.content_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newContentOnClick() {
      let _me = this;
      if (
        _me.type_list &&
        _me.type_list.length > 0 &&
        _me.type_list.some(item => {
          return item.category_list && item.category_list.length > 0;
        })
      ) {
        this.$router.push("content-new");
      } else {
        _me.$message.error("请先添加文章类型和文章分类");
      }
    },
    editModelOnclick(model) {
      this.$router.push("content-detail?id=" + model.id);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      _me.$common
        .httpPost("/api/content/admin/article/delete", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getContentList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    sortMethodOnclick(column) {
      switch (column.order) {
        case "ascending":
          this.page_sort_fields = column.prop;
          break;
        case "descending":
          this.page_sort_fields = "-" + column.prop;
          break;
        default:
          this.page_sort_fields = "";
          break;
      }
      this.getContentList();
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getContentList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getContentList();
    }
  }
};
</script>

<style scoped>
</style>

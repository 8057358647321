<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="文章分类详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveCategoryModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="category_model"
          :rules="rules"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="category_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="别名:" prop="alias">
            <el-input v-model="category_model.alias" clearable></el-input>
          </el-form-item>
          <el-form-item label="类型:">
            <el-select v-model="category_model.type_id" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="category_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number v-model="category_model.number" :min="0" label="权重"></el-input-number>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC端图标:">
            <el-input v-model="category_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="category_model.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[category_model.pc_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP端图标:">
            <el-input v-model="category_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="
                    category_model.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[category_model.wap_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="this.category_id !== undefined && this.category_id.length > 0"
            @copyInfo="copyInfo(category_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="contentCategoryFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../components/CopyPaste.vue";

export default {
  name: "ContentCategoryDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      loading: false,
      type_list: [],
      category_id: "",
      category_model: {
        id: "",
        type_id: "",
        name: "",
        alias: "",
        number: 0,
        pc_image_url: "",
        wap_image_url: "",
        status: 2
      },
      deep_copy_category_model: {},
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        alias: [
          { required: true, message: "请输入别名", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    /**
     * 进页面就深拷贝category_model,当点击保存时判断如果是新增直接删除本地存储，如果是编辑，判断deep_copy_category_model和所传参数相同属性对应的属性值是否一致，如果不一致就删除本地存储
     */
    this.getTypeList();
    this.category_id = this.$route.query.id;
    if (this.category_id !== undefined && this.category_id.length > 0) {
      this.getCategoryModel();
    }
  },
  methods: {
    getTypeList() {
      let _me = this;
      let key = "contentStructureList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.type_list = val;
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: "number"
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/type/show/structure", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.type_list = res.data === undefined ? [] : res.data;

            _me.$common.setGlobalStorage(key, _me.type_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getCategoryModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.category_id
      };
      _me.$common
        .httpPost("/api/content/admin/category/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.category_model = res.data;
            this.deep_copy_category_model = JSON.parse(
              JSON.stringify(this.category_model)
            );
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    async saveCategoryModel() {
      if (!(await this.submitValidate())) return;
      if (this.category_id !== undefined && this.category_id.length > 0) {
        this.updateCategoryModel();
      } else {
        this.newCategoryModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["category_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error"
        });
        return false;
      }
    },
    updateCategoryModel() {
      let _me = this;
      let params = {
        id: _me.category_model.id,
        type_id: _me.category_model.type_id,
        name: _me.category_model.name,
        alias: _me.category_model.alias,
        number: _me.category_model.number,
        pc_image_url: _me.category_model.pc_image_url,
        wap_image_url: _me.category_model.wap_image_url,
        status: _me.category_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/category/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            let origin_model_data = this.category_model;
            let copy_model_data = this.deep_copy_category_model;
            for (let k1 in copy_model_data) {
              for (let k2 in origin_model_data) {
                if (
                  k1 === k2 &&
                  copy_model_data[k1] !== origin_model_data[k2]
                ) {
                  _me.removeLocalStorage();
                  break;
                }
              }
            }
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newCategoryModel() {
      let _me = this;
      let params = {
        type_id: _me.category_model.type_id,
        name: _me.category_model.name,
        alias: _me.category_model.alias,
        number: _me.category_model.number,
        pc_image_url: _me.category_model.pc_image_url,
        wap_image_url: _me.category_model.wap_image_url,
        status: _me.category_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/category/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.removeLocalStorage();
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    removeLocalStorage() {
      let _me = this;
      _me.$common.removeGlobalStorage("contentStructureList");
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.contentCategoryFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_image_url":
              _me.category_model.pc_image_url = chooseFileList[0].file_url;
              break;
            case "wap_image_url":
              _me.category_model.wap_image_url = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.category_model = model;
      this.category_model.id = this.category_id;
      // 数据类型进行使用要传值
      this.type_list.forEach(item => {
        item.category_list.forEach(ele => {
          if (ele.alias == model.alias) {
            this.category_model.type_id = ele.type_id;
          }
        });
      });
    }
  }
};
</script>

<style scoped></style>

<template>
    <div v-loading="loading" class="main-frame">
        <el-container>
            <el-header class="banner-head">
                <div class="head-info">
                    <el-row>
                        <el-col style="text-align: left;" :span="12">
                            <el-page-header title="返回" @back="goBack" content="新增文章信息"></el-page-header>
                        </el-col>
                    </el-row>
                </div>
            </el-header>
            <el-main>
                <el-tabs type="card" :stretch=true :before-leave='leaveTabOnclick'>
                    <el-tab-pane label="基本信息" name="0">
                        <ContentInfoModule @updateFrame="updateFrameStatus" :content-id="content_id" :operate-type="operate_type" ref="ContentInfoModule"/>
                    </el-tab-pane>
                    <el-tab-pane label="文章详情" name="1">
                        <ContentRichTextModule :content-id="content_id" :operate-type="operate_type" ref="ContentRichTextModule"/>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import ContentRichTextModule from "./module/ContentRichTextModule";
    import ContentInfoModule from "./module/ContentInfoModule";
    export default {
        name: "ContentNewView",
        components: {
            ContentInfoModule,
            ContentRichTextModule,
        },
        data() {
            return {
                loading: false,
                content_id: '',
                operate_type: 'new',
            }
        },
        methods: {
            updateFrameStatus(value) {
                this.content_id = value
                this.operate_type = 'edit'
                // console.log("获取到子组件传值:", value)
            },
            leaveTabOnclick(activeName, oldActiveName) {
                if (activeName !== '0') {
                    // eslint-disable-next-line no-console
                    console.log(activeName, oldActiveName)
                    if (this.content_id !== undefined && this.content_id.length > 0) {
                        return true
                    } else {
                        this.$message.warning("请先添加产品基本信息")
                        return false
                    }
                }
            },
            goBack() {
                // 返回上一级路由
                this.$router.go(-1)
                // 跳转回指定路由
                // this.$router.push('/product-detail-mount')
            },
        }
    }
</script>

<style scoped></style>